<template>
  <!-- Edit produce Modal -->
    <div class="container-fluid">
      <b-form @submit.prevent="editProduce">
         <b-form-group
              label="Produce"
              label-for="Produce"
            >
              <b-form-input
                v-model="selectedCrop.crop"
                disabled
              ></b-form-input>
            </b-form-group>
         <b-form-group label="Variety" label-for="Variety">
              <b-form-input
                v-model="selectedCrop.variety"
                type="text"
                disabled
              ></b-form-input>
        </b-form-group>
          <b-form-row>
            <b-col>
                <b-form-group
                label="Units"
                label-for="Units">
                <b-form-select
                    id="test-select"
                    v-model="selectedCrop.unit"
                    :options="unitList"
                    required></b-form-select>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                label="Price Per Unit"
                label-for="Price Per Unit"
                >
                <b-form-input
                    v-model="selectedCrop.farmgateprice"
                    type="text"
                    required
                    placeholder="UGX"
                ></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>
        <div class="float-right">
          <b-button type="reset" variant="secondary"  @click="onReset">Cancel</b-button>
          <b-button type="submit" variant="primary" class="ml-2" 
          v-analytics:click="['confirm_produce_type_edit',{element:'Settings'}]">
            <b-spinner v-if="isUpdating" small label="Small Spinner"></b-spinner>
                Edit Produce
          </b-button>
        </div>
      </b-form>
      </div>
</template>
<script>

import { axiosApiInstance } from '@/plugins/axios';

export default {
  props: ["farmCrop", "units"],
  mounted() {
    this.selectedCrop = this.farmCrop;
    this.unitList = this.units;
  },
  data() {
    return {
      selectedCrop: {
        crop: "",
        variety: ""
      },
      unitList: [],
      isUpdating: false,
      baseURI:process.env.VUE_APP_BACKEND,
    };
  },
computed: {
    projectId(){
      return this.$store.state.project_id;
    },
    url(){
      return `${this.baseURI}/api/v3${this.$store.state.url_prefix}/produce`
    }
  },
methods: {
  editProduce() {
    let data = this.selectedCrop;
    this.isUpdating = true;
    axiosApiInstance
      .put(this.url + `/crops/edit/`, data, {
          params: {project_id: this.projectId}
      })
      .then((result) => {
          if(result.data.message === "Enterprise Updated") {
            this.isUpdating = false;
            this.$emit('produceEdit');
            this.onReset();
          }
      })
      .catch(error => {
          this.loading = false;
          this.error = error;
        })
  },
    onReset() {
      this.$bvModal.hide('edit-produce')
    },
  }
};
</script>
