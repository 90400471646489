<template>
  <div class="container-fluid">
    <h3 class="font-weight-bold">Manage Produce</h3>
    <hr class="mt-0 py-0 pl-1 mb-2" style="background-color: #e2e2e2" />
    <div class="d-flex">
      <b-form inline class="m-md-2">
          <b-input-group prepend="Search" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            placeholder="Search Name"
            type="search"
            v-analytics:keyup="['search_input_name',{element:'Settings'}]"
            v-model="searchfield"
            @keyup="searchByProduceName"
          ></b-form-input>
        </b-input-group>
      </b-form>
    </div>
    <b-row>
      <b-col cols="8">
        <b-table
          :fields="fields"
          :items="items"
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
        >
      <template #cell(actions)="data">
        <div>
        <b-dropdown id="dropdown-1" variant="outline-secondary" no-caret>
          <template #button-content>
            <b-icon-three-dots-vertical
              aria-hidden="true"
            ></b-icon-three-dots-vertical>
          </template>
            <b-dropdown-item variant="primary"
            v-b-modal.view-crop-details
            @click="view_details(data.item)"
            >
              View Details
            </b-dropdown-item>
          <b-dropdown-item
            variant="default"
            v-analytics:click="['edit_produce_type',{element:'Settings'}]"
            @click="editProduce(data.item)"
            >Edit</b-dropdown-item>
          <b-dropdown-item
            variant="danger"
            v-analytics:click="['delete_produce_type',{element:'Settings'}]"
            v-b-modal.delete-produce
            @click="delete_produce(data.item)">
            Delete</b-dropdown-item>
        </b-dropdown>
      </div>
      </template>
        </b-table>
      </b-col>
      <b-col cols="4 pl-4"> 
          <b-button class="w-75" variant="primary" v-b-modal.add-produce-type 
          v-analytics:click="['add_produce_type',{element:'Settings'}]"
          id="tooltip-produce-type">
              <b-icon-plus></b-icon-plus> Add Produce Type
          </b-button>
          <b-tooltip target="tooltip-produce-type" triggers="hover">
            Click button to add new crop and price to the system
        </b-tooltip>
      </b-col>
    </b-row>
    <!-- Delete produce modal -->
    <b-modal id="delete-produce" size="sm" hide-footer hide-header>
    <h5 class="text-center">Delete Produce</h5>
    <p class="text-center">Do you want to delete {{selectedCrop.crop}} ?<br>You will not be able to recover the crop.</p>
    <div>
      <b-button type="reset" variant="secondary" class="center float-left" size="sm" 
      v-analytics:click="['cancel_produce_type_delete',{element:'Settings'}]"
      @click="() => this.$bvModal.hide('delete-produce')">Cancel</b-button>
      <b-button type="submit" variant="primary" class="ml-2 float-right" size="sm" 
      v-analytics:click="['confirm_produce_type_delete',{element:'Settings'}]"
      @click="deleteProduce()">
        <b-spinner v-if="isDeleting" small label="Small Spinner"></b-spinner>
        Yes, Delete
      </b-button>
    </div>
  </b-modal>
    <!-- produce type modal -->
    <b-modal id="add-produce-type"  title="New produce type" hide-footer>
    <b-form @submit="add_produce_type" @reset="onReset"
 >
      <b-row>
        <div class="container-fluid">
          <b-form-group
                label="Produce"
                label-for="produce"
              >
                <b-form-select
                  v-model="form.crop"
                  :options="produce"
                  required
                  @change="getSelectedType"
                ></b-form-select>
            <p class="error" v-if="errors.name && form.crop !=='Coffee'">{{ errors.name['message'] }}</p>
          </b-form-group>
          <b-form-group
                label="Variety"
                label-for="variety"
                v-if="this.form.crop === 'Coffee'"
              >
            <b-form-select
              v-model="form.variety"
              :options="variety"
              @change="updateForm"
            ></b-form-select>
          <p class="error" v-if="errors.name && form.crop ==='Coffee'">{{ errors.name['message'] }}</p>
          </b-form-group>
          <b-form-group
            label="Unit"
            label-for="unit"
            description="Enter units"
          >
             <b-form-select
                  v-model="form.unit"
                  :options="units"
                  required
                ></b-form-select>
          </b-form-group>
          <b-form-group
            label="Price Per Unit"
            label-for="unit_price"
          >
            <b-form-input
              v-model="form.farmgateprice"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </b-row>
              <div class="float-right">
                <b-button type="reset" variant="secondary">Cancel</b-button>
                <b-button type="submit" variant="primary" class="ml-2">
                    <b-spinner v-if="loading" small label="Small Spinner"></b-spinner>
                    Submit
                </b-button>
            </div>
    </b-form>
  </b-modal>
    <!-- view crop details modal -->
  <b-modal id="view-crop-details" size="md" hide-footer hide-header>
    <h5 class="text-center">Produce Details</h5>
    <div class="details-header">
    <h6 class="text-center">{{selectedCrop.crop}}</h6>
    <p class="text-center">{{selectedCrop.variety}}</p>
    </div>
    <div>
    <template>
      <div>
        <b-table :items="detailItems" :fields="fieldItems"></b-table>
      </div>
    </template>
    </div>
    <div>
    </div>
    <div class="float-right">
      <b-button type="reset" variant="secondary" class="center" size="sm" @click="displayEditModal()">Edit Produce</b-button>
      <b-button type="submit" variant="primary" class="ml-2" size="sm" @click="delete_produce(selectedCrop)">
        <b-spinner v-if="isDeleting" small label="Small Spinner"></b-spinner>
        Delete Produce
      </b-button>
    </div>
  </b-modal>
   <b-modal id="edit-produce" hide-footer title="Edit Produce">
    <edit-produce :farmCrop="selectedCrop" :isExists="isExists" :units="units" v-on:produceEdit="refetchOnEdit"></edit-produce>
  </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import EditProduce from "./EditProduce.vue";

export default {
  components: { EditProduce },
  data() {
    let searchfield = "";
    let fields = [
      {key: "crop", label: "Name" },
      "unit",
      { key: "unit_price", label: "Price" },
      { key: "variety", label: "Varieties" },
      {key: "DateAdded", label: "Date Added"},
      "actions"
    ];
    let gradeFields = [
      {key: "grade", label: "Grade" },
      { key: "buying_price", label: "Buying price" },
      { key: "selling_price", label: "Selling price" }
    ];
    let items = [];
    let grades = [{ grade: "Grade 12", buying_price: 2000, selling_price: 5000 },
          { grade: "Grade 8", buying_price: 7000, selling_price: 8000 }];
    return {
      items,
      grades,
      gradeFields,
      fields,
      searchfield,
      errors: {
        name: '',
      },
      form: {
        crop: null,
        variety: null,
        },
      units: [{ text: "Select unit", value: null }, "Kg", "Mg", "g", "L"],
      produce: [{text: "Select produce", value: null}],
      variety: [{ text: "Select Variety", value: null }],
      loading: false,
      selected: [],
      selectedVariety: "All",
      filteredItems:[],
      detailItems: [],
      fieldItems: ["Crop", "Variety", "Price"],
      selectedCrop: '',
      availableStock: [],
      isDeleting: false
      };
  },
  mounted: function(){
    this.fetchData(this);
    this.fetchProduceType(this);
    this.fetchVariety(this);
    this.$analytics.track("open_produce_settings",{element:"Produce Settings"});
  },
  computed: {
    projectId(){
      return this.$store.state.project_id;
    }
  },
  methods:{
      reveal(item){
          console.log(item);
      },
      view_details(item) {
        this.selectedCrop = item;
        this.detailItems.length = 0;
        this.detailItems.push({"Crop": item.crop,"Variety": item.variety, "Price": item.farmgateprice})
    },
    editProduce(item) {
      this.selectedCrop = item;
      this.$bvModal.show("edit-produce");
    },
     displayEditModal() {
      this.$bvModal.show("edit-produce");
      this.$bvModal.hide("view-crop-details");
    },
    updateForm() {
      return (this.errors.name) ? this.errors.name = '': null
    },
      searchByProduceName() {
        if(this.searchfield.length !== 0){
        this.items = this.filteredItems.filter(
          item => item['crop'].toLowerCase().includes(
            this.searchfield.toLowerCase()));
      } else {
        this.items = this.filteredItems;
      }
      },
      getSelectedType(item) {
        this.form.variety = null;
        let arr = this.selected.filter((el) => el.crop === item.toLowerCase())
        this.variety.splice(1) && arr.length !== 0 && arr[0].varieties.length !== 0 && this.variety.push(...arr[0].varieties)
        return (this.errors.name) ? this.errors.name = '': null
      },
      fetchVariety(dataObj) {
      const baseURI = process.env.VUE_APP_BACKEND
      dataObj.parameters = {
          project_id: dataObj.projectId
        };
      axios
        .get(baseURI + '/api/v3/produce/variety', {
          headers:{'Authorization': 'Bearer ' + localStorage.getItem("access_token")},
          params: dataObj.parameters
        })
        .then((result) => {
          if(result.data.success){
            result.data.data.map((item) => {
              if(item.crop === "coffee") {
                dataObj.selected.push(item)
              }
            })
          }
        })
        .catch(error => {
          console.log(error);
        })
      },
      fetchProduceType(dataObj) {
        const baseURI = process.env.VUE_APP_BACKEND
        dataObj.parameters = {
          project_id: dataObj.projectId
        };
        axios
          .get(baseURI + '/api/v3/produce/producetype', {
            headers:{'Authorization': 'Bearer ' + localStorage.getItem("access_token")},
            params: dataObj.parameters
          })
          .then((result) => {
            if(result.data.success){
              result.data.data.map((item) => {
                dataObj.produce.push(item.crop)
              })
            }
          })
          .catch(error => {
            console.log(error);
          })
      },
      fetchData(dataObj){
        const baseURI = process.env.VUE_APP_BACKEND
        dataObj.parameters = {
          project_id: dataObj.projectId
        };
        axios
          .get(baseURI + '/api/v3/produce/crops', {
            headers:{'Authorization': 'Bearer ' + localStorage.getItem("access_token")},
            params: dataObj.parameters
          })
          .then((result) => {
            if(result.data.success){
              dataObj.items = result.data.data;
              dataObj.filteredItems = result.data.data;
            }
          })
          .catch(error => {
            console.log(error);
          })
        },
      refetchOnEdit() {
        this.fetchData(this);
      },
    onReset() {
      this.form.crop = null;
      this.form.unit = "";
      this.form.farmgateprice = "";
      this.form.variety = null;
      this.form.grade = "";
      this.$bvModal.hide('add-produce-type');
      this.errors.name = '';
    },
    isExists(formObj) {
      let produceExists;
      if(this.form.variety && this.form.crop) {
        produceExists = this.items.filter((item) => item['crop'] === formObj['crop'] &&
        item['variety'].toLowerCase() === formObj['variety'].toLowerCase())
        return produceExists.length > 0 ? true : false
      } else {
        produceExists = this.items.filter((item) => item['crop'] === formObj['crop'])
        return produceExists.length > 0 ? true : false
      }
    },
    add_produce_type(e) {
      e.preventDefault()
      const data = this.form;
      if(!this.isExists(data)) {
        this.loading = true;
        const headers = {
          'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      axios
        .post(process.env.VUE_APP_BACKEND + `/api/v3/produce/crops`, data, {
                  headers: headers,
                  params: {project_id: this.projectId}
              })
              .then((result) => {
                this.message = result.data.message;
                this.loading = false;
                this.onReset();
                this.fetchData(this);
            })
            .catch(error => {
              this.loading = false;
              this.error = error;
            })
          } else {
            this.errors.name = {message: `Produce ${data.crop} with ${data.variety ? data.variety : 'no' } variety  already exists`};
          }
      },
      delete_produce(item) {
      localStorage.setItem('doc_id', item.id)
      this.selectedCrop = item;
      this.$root.$emit("bv::show::modal", "delete-produce");
      this.$bvModal.hide('view-crop-details')
    },
    deleteProduce() {
      this.isDeleting = true;
      const baseUrl = process.env.VUE_APP_BACKEND;
      const doc_id = this.selectedCrop.id;
      axios.delete(
        baseUrl + `/api/v3/produce/crops/delete/${doc_id}`, {
          headers: {'Authorization': 'Bearer ' + localStorage.getItem("access_token")}
        }
      ).then(()=>{
        this.items = this.items.filter(el=>{
            return el.id !== doc_id
        });
        this.isDeleting = false;
        this.onReset();
        this.$bvModal.hide('delete-produce');
        this.$bvModal.hide('view-crop-details')
    })
    .catch(error => {
          this.isDeleting = false;
          this.error = error;
          alert(this.error);
    })
    },
    }
};
</script>
<style>
.error {
    color: red;
}
</style>
